import { render, staticRenderFns } from "./card-project-partner.vue?vue&type=template&id=a1023574"
import script from "./card-project-partner.vue?vue&type=script&lang=js"
export * from "./card-project-partner.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports